const dateCibleElement = document.getElementById("date-cible");
const joursElement = document.getElementById("jours");
const heuresElement = document.getElementById("heures");
const minutesElement = document.getElementById("minutes");
const secondesElement = document.getElementById("secondes");

const dateCible = Date.parse(dateCibleElement.dataset.date);

const interval = setInterval(() => {
  const tempsRestant = dateCible - Date.now();

  if (tempsRestant <= 0) {
    clearInterval(interval);
    // compteur terminé
    return;
  }

  const jours = Math.floor(tempsRestant / (1000 * 60 * 60 * 24));
  const heures = Math.floor((tempsRestant / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((tempsRestant / (1000 * 60)) % 60);
  const secondes = Math.floor((tempsRestant / 1000) % 60);

  joursElement.textContent = jours;
  heuresElement.textContent = heures;
  minutesElement.textContent = minutes;
  secondesElement.textContent = secondes;
}, 1000);

const timeline = document.getElementById("timeline");

fetch("timeline.json")
  .then(response => response.json())
  .then(data => {
    data.forEach(section => {
      const sectionDiv = document.createElement("div");
      sectionDiv.classList.add("section");
      const sectionTitle = document.createElement("h2");
      sectionTitle.innerText = section.title;
      sectionDiv.appendChild(sectionTitle);
      const sectionContent = document.createElement("p");
      sectionContent.innerText = section.content;
      sectionDiv.appendChild(sectionContent);

      const today = new Date();
      const targetDate = new Date(section.appearsOn);
      if (targetDate > today) {
        sectionDiv.classList.add("future");
      }

      timelineDiv.appendChild(sectionDiv);
    });
  });
